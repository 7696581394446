import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { SammTimeFrames } from '@nexweb/shared/data-access/samm-enums';

@Injectable({
  providedIn: 'root',
})
export class SammTimeframeService {
  private baseApiUrl = `/api/Samm/samm-time-frame`;

  constructor(private rest: RestService) {}

  getTimeFrameEndDate(timeFrameStartDate: Date, timeFrameType: SammTimeFrames): Observable<Date> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/time-frame-end-date`,
      params: {
        timeFrameStartDate: timeFrameStartDate,
        timeFrameType: timeFrameType,
      },
    };

    return this.rest.request<null, Date>(request);
  }
}
